// Import Modules
import React, { useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'

// Import Components
import { fetchTotales } from '../Sidebar/Sidebar'

// Import Hooks
import useMisc from 'hooks/useMisc'
import { useDispatch } from 'react-redux'
import { getOtrosCargos } from 'actions/misc'

const OtrosCargos = ({ factura, setFactura, handleChange }) => {
  const dispatch = useDispatch()
  const { isEdit } = factura
  const { otrosCargos } = useMisc()

  const handlePorcentajeChange = (e) => {
    handleChange(e)
    setFactura((prev) => ({
      ...prev,
      otroCargoEnLinea: {
        ...prev.otroCargoEnLinea,
        monto: prev.subtotal * (+e.target.value / 100),
      },
    }))
  }

  const handleCargoChange = (e) => {
    handleChange(e)
    setFactura((prev) => ({
      ...prev,
      otroCargoEnLinea: {
        ...prev.otroCargoEnLinea,
        monto: e.target.value,
      },
    }))
  }

  const handleDeleteLinea = useCallback(
    async (id) => {
      setFactura((prev) => ({
        ...prev,
        otroCargoEnLinea: prev?.otroCargoEnLinea?.tipoDoctoOtrosCargos?.id === id ? {} : { ...prev.otroCargoEnLinea },
        otroCargoVtaMovmList: prev?.otroCargoVtaMovmList?.filter((linea) => linea?.tipoDoctoOtrosCargos?.id !== id),
        isLoadingTotales: true,
      }))
      await fetchTotales(
        {
          ...factura,
          otroCargoEnLinea: factura?.otroCargoEnLinea?.tipoDoctoOtrosCargos?.id === id ? {} : { ...factura.otroCargoEnLinea },
          otroCargoVtaMovmList: factura?.otroCargoVtaMovmList?.filter((linea) => linea?.tipoDoctoOtrosCargos?.id !== id),
        },
        setFactura
      )
    },
    [factura, setFactura]
  )

  const handleAddCargo = async () => {
    if (!factura.otroCargoEnLinea.monto) return toast.error('Necesita al menos un producto para calcular el monto')

    setFactura((prev) => ({
      ...prev,
      otroCargoEnLinea: {},
      otroCargoVtaMovmList: [...prev.otroCargoVtaMovmList, prev.otroCargoEnLinea],
    }))

    await fetchTotales(
      {
        ...factura,
        otroCargoEnLinea: {},
        otroCargoVtaMovmList: [...factura.otroCargoVtaMovmList, factura.otroCargoEnLinea],
      },
      setFactura
    )
  }

  const handleCleanForm = () => {
    setFactura((prev) => ({
      ...prev,
      otroCargoEnLinea: {},
    }))
  }

  useEffect(() => {
    if (!otrosCargos.length) {
      dispatch(getOtrosCargos)
    }
  }, [dispatch, otrosCargos])

  return (
    <div className="sectionForm">
      <div className="titleSectionForm" style={{ width: '100%' }}>
        <h3>Otros Cargos</h3>
        <button className="clearFormLineaDetalle" onClick={handleCleanForm}>
          Limpiar Formulario
        </button>
      </div>
      <label htmlFor="numeroIdentificacion" className="text" style={{ width: '50%' }}>
        Número Identificación
        <input
          value={factura?.otroCargoEnLinea?.identificacion ?? ''}
          name="otroCargoEnLinea.identificacion"
          onChange={handleChange}
          disabled={isEdit}
          type="text"
          placeholder="Número Identificación"
          id="numeroIdentificacion"
        />
      </label>
      <label htmlFor="nombre" className="text" style={{ width: '50%' }}>
        Nombre
        <input
          disabled={isEdit}
          value={factura?.otroCargoEnLinea?.nombre ?? ''}
          onChange={handleChange}
          name="otroCargoEnLinea.nombre"
          type="text"
          placeholder="Nombre"
          id="nombre"
        />
      </label>
      <label htmlFor="detalle" className="text" style={{ width: '100%' }}>
        Detalle
        <textarea
          disabled={isEdit}
          value={factura?.otroCargoEnLinea?.detalle ?? ''}
          name="otroCargoEnLinea.detalle"
          onChange={handleChange}
          placeholder="Detalle"
          id="detalle"
          cols="30"
          rows="4"
        ></textarea>
      </label>
      <label htmlFor="tipo" className="text" style={{ width: '40%' }}>
        Tipo
        <select
          value={factura?.otroCargoEnLinea?.tipoDoctoOtrosCargos?.id ?? ''}
          onChange={handleChange}
          name="otroCargoEnLinea.tipoDoctoOtrosCargos.id"
          id="tipo"
        >
          <option key={Math.random().toString(36).slice(-10)} value="">
            Seleccione
          </option>
          {otrosCargos.map((cargo) => (
            <option key={Math.random().toString(36).slice(-10)} value={cargo.id}>
              {cargo.descripcion}
            </option>
          ))}
        </select>
      </label>
      <label htmlFor="porcentaje" className="text" style={{ width: '20%' }}>
        Porcentaje
        <input
          onChange={handlePorcentajeChange}
          value={factura?.otroCargoEnLinea?.porcentaje ?? ''}
          name="otroCargoEnLinea.porcentaje"
          disabled={isEdit}
          type="text"
          placeholder="Porcentaje"
          id="porcentaje"
        />
      </label>
      <label htmlFor="monto" className="text" style={{ width: '20%' }}>
        Monto
        <input
          type="text"
          /*onChange={handleCargoChange}*/
          disabled={isEdit}
          value={factura?.otroCargoEnLinea?.monto ?? ''}
          placeholder="Monto"
          id="monto"
        />
      </label>
      <button onClick={handleAddCargo} className="btnForm" style={{ width: '20%' }}>
        {isEdit ? <i className="bi bi-arrow-repeat"></i> : <i className="bi bi-plus-circle-fill"></i>}
        {isEdit ? 'Actualizar' : 'Agregar'}
      </button>
      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-1">
              <b>Correlativo</b>
            </li>
            {/*<li className="col-1">
              <b>Tipo</b>
            </li>*/}
            <li className="col-4">
              <b>Detalle</b>
            </li>
            <li className="col-1">
              <b>Porcentaje</b>
            </li>
            <li className="col-1">
              <b>Monto</b>
            </li>
            <li className="col-1">
              <b></b>
            </li>
          </ul>
        </div>
        <div className="bodyList">
          <ul>
            {factura.otroCargoVtaMovmList.length > 0 &&
              factura.otroCargoVtaMovmList.slice(0, 50).map((p, index) => (
                <li className="itemBodyList" key={Math.random().toString(36).slice(-10)}>
                  <ul className="contentItemBodyList" inhover="out">
                    <li className="col-1 noAction" key={Math.random().toString(36).slice(-10)}>
                      <span>{index + 1}</span>
                    </li>
                    {/*<li className="col-1 noAction" key={Math.random().toString(36).slice(-10)}>
                      <span>{p.tipoDoctoOtrosCargos?.id}</span>
                    </li>*/}
                    <li className="col-4 noAction" key={Math.random().toString(36).slice(-10)}>
                      <span>{p.detalle}</span>
                    </li>
                    <li className="col-1 noAction" key={Math.random().toString(36).slice(-10)}>
                      <span>{p.porcentaje ? p.porcentaje + '%' : ''}</span>
                    </li>
                    <li className="col-1 noAction" key={Math.random().toString(36).slice(-10)}>
                      <span>
                        {factura.idMoneda.signo}{' '}
                        {(p.porcentaje ? (p.porcentaje * (factura.subtotal + factura.iva)) / 100 : p.monto).toLocaleString('es')}
                      </span>
                    </li>
                    <li className="col-1" key={Math.random().toString(36).slice(-10)}>
                      <button className="btnDelete" onClick={() => handleDeleteLinea(p?.tipoDoctoOtrosCargos?.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </li>
                  </ul>
                </li>
              ))}
            {!factura.otroCargoVtaMovmList.length && (
              <li className="emptyResultTable">
                <span>No hay Cargos Adicionales</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default OtrosCargos
