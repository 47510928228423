import axios from 'axios'
import Cookies from 'js-cookie'
import queryString from 'query-string'

export const getInstance = async (type,file, responseType = 'json') => {
  const baseURL = getURL(type)
  const timeout = 60000

  let token = Cookies.get('savetaxToken')
  let expirationDate = new Date(Cookies.get('savetaxTokenExpires'))
  const currentDate = new Date()
  
  if (!token || currentDate > expirationDate) {
    // La cookie no existe o ha caducado, obtenemos un nuevo token
    const newToken = await getNewToken()
    if (newToken) {
      // Si se obtuvo un nuevo token, actualizamos la variable "token" y "expirationDate"
      token = newToken.token
      expirationDate = newToken.expirationDate
    } else {
      // Manejo de error, por ejemplo, redirigir a una página de inicio de sesión
      console.error('No se pudo obtener un nuevo token.')
      return null
    }
  }

  const request = axios.create({
    baseURL,
    timeout,
    headers: {
      Accept: '*/*',
      'Content-Type': file ? 'multipart/form-data' : 'application/json',
      Authorization: 'Bearer '+token,
    },
    responseType
  })

  return request
}

export const getNewToken = async () => {
  const baseURL = "https://api.security.agiltechnology.com/realms/savetax/protocol/openid-connect/token"
  const timeout = 60000
  
  const request = axios.create({
    baseURL,
    timeout,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })

  const formData = {
    grant_type: 'password',
    client_id: 'savetax-admin',
    client_secret: '4NfhjIGfGdQUGm1to1qAz31o4B1oKmXo',
    username: 'savetax',
    password: 'savetax2021$',
  }

  const encodedFormData = queryString.stringify(formData)

  try {
    const response = await request.post(baseURL, encodedFormData)

    var expires_in = response.data.expires_in
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + expires_in * 1000)
    //console.log(response.data.access_token)
    Cookies.set('savetaxToken', response.data.access_token , { expires: expirationDate })
    Cookies.set('savetaxTokenExpires', expirationDate.toUTCString())

    return {
      token: response.data.access_token,
      expirationDate: expirationDate,
    }

  } catch (error) {
    console.error('Error:', error)
    return null
  }
}


export const getURL = (option) => {
  const baseURLs = {
    admin: 'https://savetax.admin.agiltechnology.com/savetax/api/v2/',
    personas: 'https://test.facturacionsavetaxcr.com/savetax-test/api/v1/',
    productos: 'https://test.facturacionsavetaxcr.com/savetax-test/api/v1/',
    fe: 'https://test.facturacionsavetaxcr.com/savetax-test/api/v1/',
    documentos: 'https://test.facturacionsavetaxcr.com/savetax-test/api/v1/',
  }

  //console.log('Entro aca')
  // Si se proporciona una opción válida, retornar la URL correspondiente
  if (option && baseURLs[option]) {
    return baseURLs[option]
  }

  // Si no se proporciona ninguna opción o la opción no es válida, retornar la URL por defecto
  return baseURLs['admin']

  //return "https://test.facturacionsavetaxcr.com/savetax-test/api/v1/"
  //return baseURLs[option]
}